import { ref, getCurrentInstance } from "vue";
// 监听页面变化，获取最新的route
export function useOption() {
  const vm = getCurrentInstance();
  // 购买方式
  const buyTypeList = ref([
    {
      label: vm.proxy.$t("market.commission"),
      value: "1",
    },
    {
      label: vm.proxy.$t("market.fixedPrice"),
      value: "0",
    },
  ]);
  // 倍数
  const numTypeList = ref([
    {
      label: "5X",
      value: "5X",
    },
    {
      label: "10X",
      value: "10X",
    },
    {
      label: "20X",
      value: "20X",
    },
    {
      label: "50X",
      value: "50X",
    },
    {
      label: "100X",
      value: "100X",
    },
  ]);
  // 合约杠杆
  const muitList = ref([
    {
      label: "5X",
      value: "5",
    },
    {
      label: "10X",
      value: "10",
    },
    {
      label: "20X",
      value: "20",
    },
    {
      label: "50X",
      value: "50",
    },
    {
      label: "100X",
      value: "100",
    },
  ]);
  // 充值类型
  const rechargeTypeList = ref([
    {
      label: vm.proxy.$t("userText.rechargeCurrency"),
      value: "1",
    },
    {
      label: vm.proxy.$t("userText.bankRecharge"),
      value: "2",
    },
    {
      label: vm.proxy.$t("userText.manualService"),
      value: "3",
    },
    {
      label: "C2C",
      value: "4",
    },
  ]);
  // 充值币类型
  const chainList = ref([
    {
      label: "ERC20",
      value: "ERC20",
    },
    {
      label: "TRC20",
      value: "TRC20",
    },
    {
      label: "USDC",
      value: "USDC",
    },
  ]);

  // 证件类型
  const cardList = ref([
    {
      name: vm.proxy.$t("userText.drivingLicense"),
      value: "1",
    },
    {
      name: vm.proxy.$t("userText.passport"),
      value: "2",
    },
    {
      name: vm.proxy.$t("userText.idCardType"),
      value: "3",
    },
  ]);
  // 根据证件类型val，获取中文
  const getNowCard = (val) => {
    if (val) {
      return cardList.value.find((item) => item.value == val).name || "";
    } else {
      return "";
    }
  };
  // 钱包类型
  const bankTypeList = ref([
    {
      label: "USDT-TRC20",
      value: "USDT-TRC20",
    },
    {
      label: "USDT-ERC20",
      value: "USDT-ERC20",
    },
    {
      label: "ETH",
      value: "ETH",
    },
    {
      label: "BTC",
      value: "BTC",
    },
  ]);
  // 充值状态
  const rechargeStatusList = ref([
    vm.proxy.$t("order.callbacking"),
    vm.proxy.$t("userText.auditIng"),
    vm.proxy.$t("tips.success"),
    vm.proxy.$t("tips.error"),
  ]);
  // 提现状态
  const withdrawalStatusList = ref([
    vm.proxy.$t("order.auditIng"),
    vm.proxy.$t("userText.auditIng"),
    vm.proxy.$t("tips.success"),
    vm.proxy.$t("tips.error"),
  ]);
  return {
    buyTypeList,
    numTypeList,
    muitList,
    rechargeTypeList,
    cardList,
    getNowCard,
    bankTypeList,
    chainList,
    rechargeStatusList,
    withdrawalStatusList,
  };
}
