<template>
  <div class="container flex">
    <div class="extractItem flex-1">
      <h3>{{ $t("market.withdraw") }}</h3>
      <div class="extractContent extractLeft">
        <p>{{ $t("market.chainChoosePlac") }}</p>
        <div class="selectBox">
          <el-select
            v-model="form.currency"
            style="width: 100%"
            size="small"
            :placeholder="$t('market.accountChoose')"
          >
            <el-option
              v-for="item in balanceList"
              :key="item.currency"
              :label="item.currency_name"
              :value="item.currency"
            >
            </el-option>
          </el-select>
        </div>
        <!-- 选择账户 -->
        <p>{{ $t("market.accountChoose") }}</p>
        <div class="selectBox">
          <el-select
            v-model="form.accountVal"
            style="width: 100%"
            size="small"
            :placeholder="$t('market.accountChoose')"
          >
            <el-option
              v-for="item in accountTypeList"
              :key="item.id"
              :label="item.bank_name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <p>{{ $t("market.accountBalance") }}</p>
        <h2>{{ micro_balance }}</h2>

        <p>{{ $t("market.withdrawalAmount") }}</p>
        <div class="selectBox">
          <el-input-number
            size="small"
            style="width: 100%"
            v-model="form.num"
            :min="0"
            :placeholder="$t('market.withdrawalAmountPlac')"
            controls-position="right"
          ></el-input-number>
        </div>
        <span style="font-size: 12px; color: #999"
          >{{ $t("market.withdrawalTips") }}：{{ min_number }}USDT</span
        >
        <div class="btnBox">
          <el-button type="primary" @click="submit">{{
            $t("market.submitBtnText")
          }}</el-button>
        </div>
      </div>
    </div>
    <div class="extractItem flex-1">
      <h3>{{ $t("market.withdrawalDetails") }}</h3>
      <div class="extractContent extractRight">
        <el-table
          :data="detailTable"
          :empty-text="$t('rankText.emptyText')"
          style="width: 100%"
          size="small"
        >
          <el-table-column
            prop="name"
            align="center"
            :label="$t('market.withdrawalAmount')"
          >
            <template slot-scope="scope">
              {{ toSelfFixed(scope.row.number, 2) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="create_time"
            align="center"
            :label="$t('market.time')"
          >
          </el-table-column>
          <el-table-column
            prop="name"
            align="center"
            :label="$t('market.status')"
          >
            <template slot-scope="scope">
              <div class="statusBox">
                <span>{{ withdrawalStatusList[scope.row.status] }}</span>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, getCurrentInstance, onMounted, computed } from "vue";
import { toSelfFixed } from "@/utils/common";
import { Message } from "element-ui";
import { getWallet, getcash_info, outBank, cashlistlog } from "@/api/user";
import { useLang } from "@/hooks/useLang";
import { useOption } from "@/hooks/useOption";
const { withdrawalStatusList } = useOption();
const { lang } = useLang();
const vm = getCurrentInstance();
const form = ref({
  currency: null,
  accountVal: null,
  num: 0,
});
// 提交明细表格
const detailTable = ref([]);
// 提交
const submit = async () => {
  if (!form.value.accountVal) {
    Message.error(vm.proxy.$t("market.accountChoosePlac"));
    return false;
  } else if (!form.value.num) {
    Message.error(vm.proxy.$t("market.withdrawalAmountPlac"));
    return false;
  } else if (form.value.num < min_number.value) {
    Message.error(vm.proxy.$t("market.nowNumberError"));
    return false;
  }
  const { type, message } = await outBank({
    currency: form.value.currency,
    number: form.value.num,
    bankid: form.value.accountVal,
    lang: lang.value,
  });
  if (type == "ok") {
    Message.success(vm.proxy.$t(message));
    getLogData();
  } else {
    Message.error(vm.proxy.$t(message));
  }
};
// 获取提现明细
const getLogData = async () => {
  const { message } = await cashlistlog({ lang: lang.value });
  detailTable.value = message.data;
};
// 账户余额
const micro_balance = computed(() => {
  const arr = balanceList.value.filter((e) => {
    return e.currency == form.value.currency;
  });
  return arr.length > 0 ? arr[0].micro_balance : 0;
});
// 最低提现金额
const min_number = computed(() => {
  const arr = balanceList.value.filter((e) => {
    return e.currency == form.value.currency;
  });
  return arr.length > 0 ? arr[0].min_number : 0;
});
const balanceList = ref([]);
const accountTypeList = ref([]);
onMounted(async () => {
  const { message } = await getWallet({ lang: lang.value });
  balanceList.value = message.micro_wallet.balance.filter(
    (item) => item.currency == 3 || item.currency == 2 || item.currency == 1
  );
  form.value.currency = balanceList.value[0].currency;
  const { message: bankList } = await getcash_info({ lang: lang.value });
  accountTypeList.value = bankList;
  form.value.accountVal = accountTypeList.value[0].id;
  getLogData();
});
</script>

<style lang="scss" scoped>
.container {
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
  .extractItem {
    width: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
      font-size: 28px;
      font-weight: bold;
    }
    &:nth-child(1) {
      margin-right: 30px;
    }
    .extractContent {
      box-shadow: $box-shadow;
      background: $section-color;
      border: 1px solid $market-list-hover !important;
      border-radius: 10px;
      padding: 40px;
      width: 100%;
      box-sizing: border-box;
    }
    .extractLeft {
      p {
        font-size: 14px;
      }
      h2 {
        color: #5670f6;
        font-size: 32px;
        font-weight: bold;
        text-transform: uppercase;
      }
      .btnBox {
        .el-button {
          width: 100%;
          margin-top: 20px;
          background-color: #5670f6;
        }
      }
    }
    .extractRight {
      padding: 0;
    }
  }
}
</style>
